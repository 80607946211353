export * from './src/QuoteService'
export * from './src/QuoteApi'
        import * as me from '@peachy/quote-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dan-parker/workspace/duplicate/peachy-repo/comp-kit/quote/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/quote-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    