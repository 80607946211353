export * from './src/IamService'
export * from './src/IamUserService'

        import * as me from '@peachy/iam-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dan-parker/workspace/duplicate/peachy-repo/comp-kit/iam/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/iam-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    