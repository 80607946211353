export * from './src/address-types'
export * from './src/address-kit'


export * from './src/geo-location-api'


        import * as me from '@peachy/geo-location-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dan-parker/workspace/duplicate/peachy-repo/comp-kit/geo-location/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/geo-location-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    